import './styles/styles.scss';
import Header from './scripts/components/header'
import Navigation from './scripts/components/navigation'

($ => {
    $(document).ready(() => {
        new Header()
        new Navigation()

        if ($('.ProductFilters').length) {
            import('./scripts/components/productSearchFilters')
                .then(({ default: ProductSearchFilters }) => {
                    $('.ProductFilters').each((i, el) => { new ProductSearchFilters(el) })
                })
        }

        if ($('.ProductTabsContainer').length) {
            import('./scripts/components/productTabs')
                .then(({ default: ProductTabs }) => {
                    $('.ProductTabsContainer').each((i, el) => { new ProductTabs(el) })
                })
        }

        if ($('.rotator').length) {
            import('./scripts/components/rotator')
                .then(({ default: Rotator }) => {
                    $('.rotator').each((i, el) => { new Rotator(el) })
                })
        }
    })
})(jQuery)