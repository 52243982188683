import $ from 'jquery'

export default class Header {
    constructor() {
        this.$header = $('.site__header')
        this._resizeObserver = new ResizeObserver(this.resizeCallback)
        this.init()
    }

    addEventListeners() {
        this._resizeObserver.observe(this.$header[0])
    }

    resizeCallback(entries) {
        entries.forEach(entry => {
            document.body.style.setProperty(
                '--header-height',
                `${entry.contentRect.height}px`
            )
        });
    }

    init() {
        this.addEventListeners()
    }
}