import $ from 'jquery'

export default class Navigation {
    constructor() {
        this.$menu = $('.site__navigation')
        this.$open = $('.navigation__button.-open')
        this.$close = $('.navigation__button.-close')
        this.$menu.on('opened', $.proxy(this.showCloseButton, this))
        this.$menu.on('closed', $.proxy(this.showMenuButton, this))
        this.$menu.on('click', '.toggleSubMenu', $.proxy(this.toggleSubMenu, this))
        this.$open.on('click', $.proxy(this.openMenu, this))
        this.$close.on('click', $.proxy(this.closeMenu, this))
        this.init()
    }

    openMenu() {
        if (!this.$menu.hasClass('-closed')) return
        this.$menu.one('transitionstart', () => { this.$menu.trigger('opened') })
        this.$menu.removeClass('-closed')
    }

    closeMenu() {
        if (this.$menu.hasClass('-closed')) return
        this.$menu.one('transitionstart', () => { this.$menu.trigger('closed') })
        this.$menu.addClass('-closed')
    }

    showCloseButton() {
        this.toggleButton(this.$open)
        this.toggleButton(this.$close, false)
    }

    showMenuButton() {
        this.toggleButton(this.$open, false)
        this.toggleButton(this.$close)
    }

    toggleButton(button, state = true) {
        button.toggleClass('-hidden', state)
    }

    toggleSubMenu(e) {
        const button = $(e.currentTarget)
        const menu = button.siblings('.navigation__list')
        button.toggleClass('-open')
        menu.toggleClass('-closed')
    }

    init() {
        this.$menu.find('.navigation__item .navigation__list').addClass('-closed')
    }
}